import React, { Component } from "react";
import { navigate, graphql } from "gatsby";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import { RadioInputGroup, RadioInput } from "@instructure/ui-forms";
import { ScreenReaderContent } from "@instructure/ui-a11y";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";
import ReactMedia from "react-media";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Layout from "../../../../components/layout";
import QuestionHeader from "../../../../components/question-header";
import SEO from "../../../../components/seo";
import styles from "./classification-activity.module.css";
import {
  setCurrentModule,
  setCurrentStep,
  saveLocalStateToGlobalState
} from "../../../../state/actions";
import { func, shape, object } from "prop-types";

const RIGHTS = [
  {
    id: 1,
    text: "The right to take the child to the state fair",
    retained: false
  },
  {
    id: 2,
    text:
      "The right to approve or deny that the child in care can attend a sports event in the city",
    retained: false
  },
  {
    id: 3,
    text:
      "The right to take the child to the library using public transportation",
    retained: false
  },
  {
    id: 4,
    text: "The right to approve or deny that the child can travel out of state",
    retained: true
  },
  {
    id: 5,
    text: "The right to consent that the child plays at a friend's house",
    retained: false
  },
  {
    id: 6,
    text: "The right to take the child to the doctor",
    retained: false
  }
];
const grid = 8;

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "rgba(234, 60, 150, 0.25)" : "white",
  padding: grid,
  // width: 250,
  border: "2px black solid"
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "rgba(99, 175, 0, 1)" : "#472453",
  color: isDragging ? "#000" : "#fff",
  // styles we need to apply on draggables
  ...draggableStyle
});

class ClassificationActivity extends Component {
  state = {
    hasSubmitted: false,
    hasSeenFeedback: false,
    right: RIGHTS.map(() => null),
    ...this.props.savedState
  };

  static propTypes = {
    handleNavigate: func,
    goToStep: func,
    data: shape({
      file: shape({
        childImageSharp: shape({
          fixed: object
        })
      })
    })
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(2);
    this.props.goToStep(2);
  }

  goForward = () => {
    if (!this.state.hasSubmitted) {
      this.setState({
        hasSubmitted: true
      });
    } else {
      navigate("/modules/travel/process");
    }
  };

  goBack = () => {
    navigate("/modules/travel/rights");
  };

  updateActivityState = (value, index) => {
    const newRight = this.state.right.slice();
    newRight[index] = value;
    this.setState(
      {
        right: newRight
      },
      () => {
        this.props.saveLocalStateToGlobalState(
          {
            right: newRight
          },
          {
            moduleId: 2,
            stepId: 2
          }
        );
      }
    );
  };

  onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      // ???
    } else {
      switch (destination.droppableId) {
        case "unspecifiedArea":
          this.updateActivityState(null, result.draggableId);
          break;
        case "biologicalArea":
          this.updateActivityState("biological", result.draggableId);
          break;
        case "fosterArea":
          this.updateActivityState("foster", result.draggableId);
          break;
      }
    }
  };

  renderSmall = () => {
    return (
      <>
        <div className={styles.instructions}>
          <QuestionHeader
            icon={
              <span
                className={styles.emoji}
                role="img"
                aria-label="Thinking Face"
              >
                🤔
              </span>
            }
          >
            <span>
              Decide if the following rights are given to the foster parent or
              retained by the biological parent.
            </span>
          </QuestionHeader>
        </div>
        <div className={styles.smallContainer}>
          {RIGHTS.map((right, index) => (
            <div key={right.id} className={styles.rightContainer}>
              <div className={styles.rightName}>{right.text}</div>
              <div className={styles.rightToggle}>
                <RadioInputGroup
                  name={`right${right.id}`}
                  value={this.state.right[index]}
                  onChange={e =>
                    this.updateActivityState(e.currentTarget.value, index)
                  }
                  description={
                    <ScreenReaderContent>{`Does ${right} belong to the biological parent or the foster parent?`}</ScreenReaderContent>
                  }
                  variant="toggle"
                  size="small"
                >
                  <RadioInput
                    label="Biological"
                    value="biological"
                    context="off"
                  />
                  <RadioInput label="Foster" value="foster" context="off" />
                </RadioInputGroup>
                {this.state.hasSubmitted ? (
                  this.state.right[index] === "biological" ? (
                    RIGHTS[index].retained ? (
                      <div className={styles.smallFeedbackPositive}>
                        Correct
                      </div>
                    ) : (
                      <div className={styles.smallFeedbackNegative}>
                        Incorrect
                      </div>
                    )
                  ) : !RIGHTS[index].retained ? (
                    <div className={styles.smallFeedbackPositive}>Correct</div>
                  ) : (
                    <div className={styles.smallFeedbackNegative}>
                      Incorrect
                    </div>
                  )
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  renderLarge = () => {
    const unspecifiedItems = RIGHTS.map((item, index) => {
      if (this.state.right[index] == null) {
        return { id: index.toString(), right: item, content: item.text };
      }
      return null;
    }).filter(x => !!x);

    const biologicalItems = this.state.right
      .map((value, index) => {
        if (value === "biological") {
          return {
            id: index.toString(),
            right: RIGHTS[index],
            content: RIGHTS[index].text
          };
        }
        return null;
      })
      .filter(x => !!x);

    const fosterItems = this.state.right
      .map((value, index) => {
        if (value === "foster") {
          return {
            id: index.toString(),
            right: RIGHTS[index],
            content: RIGHTS[index].text
          };
        }
        return null;
      })
      .filter(x => !!x);
    return (
      <>
        <div className={styles.instructions}>
          <QuestionHeader
            icon={
              <span
                className={styles.emoji}
                role="img"
                aria-label="Thinking Face"
              >
                🤔
              </span>
            }
          >
            <span>Drag each activity to the proper category.</span>
          </QuestionHeader>
        </div>
        <div className={styles.largeContainer}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable
              className={styles.dropArea}
              droppableId="unspecifiedArea"
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <h3>Rights</h3>
                  {unspecifiedItems.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {item.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable className={styles.dropArea} droppableId="biologicalArea">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <h3>Biological Parent</h3>
                  {biologicalItems.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {item.content}
                          {this.state.hasSubmitted ? (
                            item.right.retained ? (
                              <div className={styles.largeFeedbackPositive}>
                                Correct
                              </div>
                            ) : (
                              <div className={styles.largeFeedbackNegative}>
                                Incorrect
                              </div>
                            )
                          ) : null}
                        </div>
                      )}
                    </Draggable>
                  ))}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable className={styles.dropArea} droppableId="fosterArea">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <h3>Foster Parent</h3>
                  {fosterItems.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {item.content}
                          {this.state.hasSubmitted ? (
                            !item.right.retained ? (
                              <div className={styles.largeFeedbackPositive}>
                                Correct
                              </div>
                            ) : (
                              <div className={styles.largeFeedbackNegative}>
                                Incorrect
                              </div>
                            )
                          ) : null}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </>
    );
  };

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Travel - Rights"
          keywords={["utah foster care", "foster care"]}
        />
        <div
          className={`${styles.moduleGridContainer} ${
            this.state.hasSubmitted ? styles.hasSubmitted : ""
          }`}
        >
          <ReactMedia query={{ minWidth: "768px" }}>
            {matches => (matches ? this.renderLarge() : this.renderSmall())}
          </ReactMedia>
          {this.state.hasSubmitted && (
            <div className={styles.feedbackArea}>
              All of the rights that pertain to in-state travel are given to the
              foster parent. The rights pertaining to out-of-state travel are
              retained by the biological parents.
            </div>
          )}
          <div className={styles.backButtonArea}>
            <Button
              onClick={this.goBack}
              variant="success"
              icon={IconArrowOpenStart}
            />
          </div>
          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.goForward}
              variant="success"
              icon={IconArrowOpenEnd}
              disabled={this.state.right.some(x => !x)}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  savedState: state.modules.find(x => x.id === 2).steps.find(x => x.id === 2)
    .localState,
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    },
    saveLocalStateToGlobalState(state, info) {
      dispatch(saveLocalStateToGlobalState(state, info));
    }
  };
};

export const query = graphql`
  query {
    family: file(name: { eq: "family" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    disney: file(name: { eq: "disney-castle" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default connect(
  stateToProps,
  dispatchToProps
)(ClassificationActivity);
